import {
    db,
    doc,
    getDoc,
    auth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    setPersistence,
    browserLocalPersistence,
    functions,
    httpsCallable,
} from "../../firebaseConfig"

export default {
    state: {
        authUser: null, // firebase auth object
        user: null, // user object
        isAdmin: false, // boolean
        isConnected: false,
        // 
        authError: ""
    },
    mutations: {
        setAuthUser(state, authUser) {
            state.authUser = authUser
        },
        setUser(state, user) {
            state.user = user
        }
    },
    actions: {
        async setup({ commit }, authUser) {
            commit('setAuthUser', authUser)
            let user = null
            if (authUser) {
                let userDoc = await getDoc(doc(db, 'users', authUser.uid))
                if (userDoc.exists()) {
                    user = userDoc.data()
                    console.log("userDoc.data user", user)
                } else {
                    console.log("state.setup.else userDOc not in db")
                }
            }
            commit('setUser', user)
        },
        async signIn({ commit }, paylod) {
            console.log("BEFORE SignIN")
            let res = null
            await setPersistence(auth, browserLocalPersistence)
                .then(async () => {
                    let userCredential = await signInWithEmailAndPassword(auth, paylod.mail, paylod.password)
                    const authUser = userCredential.user;
                    commit('setAuthUser', authUser)

                    let userDoc = await getDoc(doc(db, 'users', authUser.uid))
                    //console.log("got authUser doc", userDoc)
                    commit('setUser', userDoc.data())

                    authUser['valid'] = userDoc.data().valid
                    res = authUser
                })
                .catch((err) => {
                    throw err
                })
            return res
        },
        async signOutStore({ commit }) {
            console.log("Store.signOut")
            await signOut(auth)
                .then(() => {
                    commit('setAuthUser', null)
                    commit('setUser', null)
                })
                .catch((err) => {
                    console.log("state.signOut.err", err)
                })
        },
        async sendEmailVerification(context, email) {
            console.log("STATE.seding verif", email)
            const func = httpsCallable(functions, 'sendMail')
            const res = await func({
                action: 'MAIL_VERIF',
                email: email
            })
            console.log("STATE.sendEmailVerification res", res)
        },
        async sendPasswordReset(context, email) {
            console.log("STATE.seding pass reset", email)
            let res = await sendPasswordResetEmail(auth, email)
            console.log("STATE.seding pass res", res)
        },
        async sendContactMail(context, formData) {
            console.log("STATE.seding contact ", formData)
            const func = httpsCallable(functions, 'sendMail')

            formData['action'] = 'CONTACT'
            const res = await func(formData)
            console.log("STATE.seding contact res", res)
        }
    },
    getters: {
        authUser(state) {
            return state.authUser
        },
        user(state) {
            return state.user
        },
        isAdmin(state) {
            return state.isAdmin
        }
    }
}