import {
    db,
    collection,
    query,
    orderBy,
    getDocs,
    //startAt, 
    limit,
    //endAt,
    functions,
    httpsCallable,
    endBefore,
    doc,
    limitToLast,
    startAfter,
    getDoc,
    startAt
} from './../../firebaseConfig'

export default {
    state: {
        authUsers: [],
        total: 0
    },
    mutations: {
        setTotal(state, total) {
            state.total = total
        },
        setAuthUsers(state, authUsers) {
            state.authUsers = authUsers
        },
        removeAuthUser(state, user) {
            state.authUsers = state.authUsers.filter(u => u.uid != user.uid)
        }
    },
    actions: {
        async findAuthUsersExport() {
            let users = []

            const querySnapshot = await getDocs(collection(db, "users"));
            querySnapshot.forEach((doc) => {
                const user = doc.data()
                user['uid'] = doc.id
                users.push(user)
            });

            return users
        },
        // get total number of users
        async findTotal({
            commit
        }) {
            const ref = doc(db, "counters", "counterDoc");
            const snap = await getDoc(ref)
            let total = 0

            if (snap.exists()) {
                total = snap.data().total
            } else {
                console.log("No such document!");
            }
            console.log("TOTAL", total)
            commit('setTotal', total)
        },
        // 
        async next({
            getters,
            commit,
            dispatch
        }, pagination) {
            let users = []
            const ref = collection(db, "users");
            let last = getters.authUsers[getters.authUsers.length - 1]
            console.log("last", last)
            const q = query(
                ref,
                orderBy('creationTime', "desc"),
                startAfter(last.creationTime),
                limit(pagination.limit)
            )

            const snapshot = await getDocs(q)
            snapshot.forEach((doc) => {
                let user = doc.data()
                user['uid'] = doc.id
                users.push(user)
            });

            await dispatch('findTotal')
            commit('setAuthUsers', users)
        },
        async prev({
            getters,
            commit,
            dispatch
        }, pagination) {
            let users = []
            const ref = collection(db, "users");
            console.log("getters/authUsers", getters.authUsers)
            let first = getters.authUsers[0]
            console.log("first", first)
            const q = query(
                ref,
                orderBy('creationTime', "desc"),
                endBefore(first.creationTime),
                limitToLast(pagination.limit)
            )

            const snapshot = await getDocs(q)
            snapshot.forEach((doc) => {
                let user = doc.data()
                user['uid'] = doc.id
                users.push(user)
            });

            await dispatch('findTotal')
            commit('setAuthUsers', users)
        },
        async reload({
            getters,
            commit,
            dispatch
        }, pagination) {
            console.log("STORE.relaod")
            let users = []
            const ref = collection(db, "users");
            let first = getters.authUsers[0]

            const q = query(
                ref,
                orderBy('creationTime', "desc"),
                startAt(first.creationTime),
                limit(pagination.limit)
            )

            const snapshot = await getDocs(q)
            snapshot.forEach((doc) => {
                let user = doc.data()
                user['uid'] = doc.id
                users.push(user)
            });
            await dispatch('findTotal')
            commit('setAuthUsers', users)
        },
        async load({
            commit,
            dispatch
        }, pagination) {
            let users = []
            const ref = collection(db, "users");

            const q = query(
                ref,
                orderBy('creationTime', "desc"),
                limit(pagination.limit)
            )

            const snapshot = await getDocs(q)
            snapshot.forEach((doc) => {
                let user = doc.data()
                user['uid'] = doc.id
                users.push(user)
            });

            await dispatch('findTotal')
            commit('setAuthUsers', users)
        },
        async saveAuthUser(context, user) {
            console.log("STATE.saveUser", user, user.uid)
            try {
                if (!user.uid) {
                    const getAuthUser = httpsCallable(functions, 'createAuthUser')
                    const res = await getAuthUser(user)
                    console.log("state.authUser", res)
                } else {
                    const updateAuthUser = httpsCallable(functions, 'updateAuthUser')
                    const res = await updateAuthUser(user)
                    console.log("state.updateUser", res)
                }
            } catch (err) {
                console.log("UserManager.store.saveAuthUser err", err)
                throw err
            }
        },
        async removeAuthUser({
            commit
        }, user) {
            try {
                if (user) {
                    const removeUser = httpsCallable(functions, 'removeAuthUser')
                    const res = await removeUser(user)
                    console.log("STATE.removeUser res", res)
                    commit('removeAuthUser', user)
                }
            }catch (err) {
                console.log("UserManager.store.removeAuthUser err", err)
                throw err
            }

        },
        async sendMailTest() {
            const send = httpsCallable(functions, 'mailTest')
            let res = await send()
            console.log("res", res)
        }
    },
    getters: {
        total(state) {
            return state.total
        },
        authUsers(state) {
            return state.authUsers
        }
    }
}