import { initializeApp } from "firebase/app";
import {
    updateDoc,
    arrayRemove,
    arrayUnion,
    getDoc,
    getDocs,
    collection,
    //setDoc,
    doc,
    runTransaction,
    getFirestore,
    startAt, 
    endAt,
    query,
    orderBy,
    limit,
    endBefore, 
    limitToLast, 
    startAfter
} from "firebase/firestore";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    signOut,
    setPersistence,
    browserLocalPersistence,
    onAuthStateChanged
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAKP4CHREgDnO-vdKKgC8rqLOm6fmW9xBQ",
    authDomain: "troc-raismois-web.firebaseapp.com",
    projectId: "troc-raismois-web",
    storageBucket: "troc-raismois-web.appspot.com",
    messagingSenderId: "646822429132",
    appId: "1:646822429132:web:3102dec5967d55d2649d69"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore()
const functions = getFunctions()

export {
    app, 
    db, 
    // functions
    functions, 
    httpsCallable,
    auth, 
    // auth
    onAuthStateChanged, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    sendEmailVerification,
    sendPasswordResetEmail,
    signOut, 
    setPersistence, 
    browserLocalPersistence,
    // db
    updateDoc,
    arrayRemove,
    arrayUnion,
    getDoc,
    getDocs,
    collection,
    doc,
    runTransaction,
    startAt, 
    endAt,
    query,
    orderBy,
    limit,
    endBefore, 
    limitToLast, 
    startAfter
}