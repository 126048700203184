<template>
  <v-app>
    <!-- HeaderApp -->
    <Header v-if="!isAdminView" @auth-event="startAuthEvent" />

    <Login
      v-if="authDialog"
      :show="authDialog"
      @auth-event="endAuthEvent"
      @mail-not-valid-event="startMailNotValidEvent"
      @create-account-event="startCreateAccountEvent"
      @user-not-valid-event="startUserNotValidEvent"
      @start-reset-pass-event="startResetPasswordEvent"
    />

    <SignUp
      v-if="signUp.dialog"
      :show="signUp.dialog"
      :userProp="signUp.user"
      :CREATE="false"
      :SIGNUP="true"
      @end-user-create-event="endCreateAccountEvent"
    />

    <MailNotValidDialog
      v-if="mailNotValid.dialog"
      :mailNotValidDialog="mailNotValid.dialog"
      :email="mailNotValid.email"
      @end-mail-not-valid-event="endMailNotValidEvent"
    />

    <InformationDialog
      v-if="information.dialog"
      :informationDialog="information.dialog"
      :title="information.title"
      :text="information.text"
      :icon="information.icon"
    />

    <ResetPasswordDialog
      v-if="resetPasswordDialog"
      :resetPasswordDialog="resetPasswordDialog"
      @end-reset-pass-event="endResetPasswordEvent"
    />

    <!-- router -->
    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>

    <!-- footer -->
    <Footer v-if="!isAdminView" />
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
const Login = () => import("./components/dialog/Login.vue");
const InformationDialog = () =>
  import("./components/dialog/InformationDialog.vue");
const ResetPasswordDialog = () =>
  import("./components/dialog/ResetPasswordDialog.vue");
const MailNotValidDialog = () =>
  import("./components/dialog/MailNotValidDialog.vue");
const SignUp = () => import("./components/dialog/SignUp.vue");

export default {
  name: "App",
  components: {
    Footer,
    Header,
    Login,
    InformationDialog,
    MailNotValidDialog,
    SignUp,
    ResetPasswordDialog,
  },
  data: () => ({
    admin: false,
    authDialog: false,
    // when mail not verified
    resetPasswordDialog: false,
    // sign up pop-up
    signUp: {
      dialog: false,
      user: {
        name: "",
        surname: "",
        town: "",
        email: "",
        password: "",
        number: "",
        credit: 0,
        valid: false,
        admin: false,
      },
    },
    // information pop up
    information: {
      dialog: false,
      title: "",
      text: "",
      icon: "",
    },
    // mail not validated pop up
    mailNotValid: {
      dialog: false,
      email: "",
    },
  }),
  async created() {
    document.title = "Troc D'Heures Raismois";
  },
  methods: {
    // password reset event
    startResetPasswordEvent() {
      console.log("APP.start pass res event");
      this.authDialog = false;
      this.resetPasswordDialog = true;
    },
    endResetPasswordEvent() {
      setTimeout(() => {
        this.resetPasswordDialog = false;
      }, 5000);
      //this.resetPasswordDialog = false;
    },
    // account not valid (5 euros missing) event
    startUserNotValidEvent() {
      console.log("APP.user not valid event fired");
      this.information.title = "";
      this.information.text =
        "Votre compte n'a pas encore été activé. Veuillez contacter l'équipe Troc Raismois";
      this.information.icon = "mdi-account-alert";
      this.information.dialog = true;

      setTimeout(() => {
        this.information.title = "";
        this.information.text = "";
        this.information.icon = "";
        this.information.dialog = false;
      }, 5000);
    },
    endUserNotValidEvent() {
      console.log("APP. end NOT valid event");
      this.userNotValidDialog = false;
    },
    // Create account event
    startCreateAccountEvent() {
      console.log("sign Up event");
      this.authDialog = false;
      this.signUp.dialog = true;
    },
    endCreateAccountEvent(e) {
      this.signUp.dialog = false;
      // Fire mail validation dialog
      if (e) {
        this.mailNotValid.dialog = true;
      }
    },
    // Mail not valid
    startMailNotValidEvent(email) {
      this.email = email;
      this.mailNotValid.dialog = true;
    },
    endMailNotValidEvent() {
      this.email = "";
      this.mailNotValid.dialog = false;
    },
    async startAuthEvent() {
      console.log("checking if connected");
      let authUser = this.$store.getters.user; //isConnected();
      console.log("isConnected authUser", authUser);
      if (authUser) {
        console.log("already logged");
        this.goToPath("/espace");
      } else {
        //this.authData.mail = "";
        //this.authData.password = "";
        this.authDialog = true;
      }
    },
    endAuthEvent() {
      setTimeout(() => {
        this.authDialog = false;
      }, 50);
      //this.authDialog = false;
    },
    test() {
      let storeUser = this.$store.getters.actualUser;
      console.log("storeUser", storeUser);
    },
  },
  computed: {
    actualUser() {
      console.log("actualUser", this.$store.getters.actualUser);
      return this.$store.getters.actualUser;
    },
    isAdminView() {
      //console.log("actualRoute", this.$route.name);
      return this.$route.name == "Admin";
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.title-font {
  font-family: "Open Sans", sans-serif;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}
</style>