import {
    db, 
    updateDoc,
    arrayRemove,
    arrayUnion,
    doc,
    getDocs,
    collection,
    runTransaction
} from "./../../firebaseConfig"

// Unique ID generator
function generateId() {
    return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
}

export default {
    state: {
        services: [],
        categServiceDoc: {
            other: doc(db, 'services', 'other'),
            animal: doc(db, 'services', 'animal'),
            home: doc(db, 'services', 'home'),
            kitchen: doc(db, 'services', 'kitchen'),
            computer: doc(db, 'services', 'computer'),
            garden: doc(db, 'services', 'garden'),
            hobby: doc(db, 'services', 'hobby'),
            teaching: doc(db, 'services', 'teaching'),
            transport: doc(db, 'services', 'transport'),
            diy: doc(db, 'services', 'diy'),
            social: doc(db, 'services', 'social'),
            loan: doc(db, 'services', 'loan'),
            gift: doc(db, 'services', 'gift')
        }
    },
    mutations: {
        setServices(state, services) {
            state.services = services
        }
    },
    actions: {
        // pagination to-do
        async findServices({ commit }) {
            let services = []
            
            const querySnapshot = await getDocs(collection(db, "services"));
            querySnapshot.forEach((doc) => {
                let items = doc.data().services
                //console.log("items", items, doc.id)
                // items.forEach((item) => {
                //     console.log(doc.id, "item", item)
                // })
                services = services.concat(items)
            });
            //console.log("commit services", services)
            commit('setServices', services)
        },
        async saveService(context, service) {
            //console.log("STORE saveService", service)
            service['creationTime'] = new Date().getTime()
            if (!service.id) {
                service.id = generateId()
                const serviceDocRef = doc(db, 'services', service.category)
                await updateDoc(
                    serviceDocRef, {
                    services: arrayUnion(service)
                })
            } else {
                /*
                 * Read the while service doc so we have up-to-date list
                 * only afte then update service
                 */
                await runTransaction(db, async (trans) => {
                    const serviceDocRef = doc(db, 'services', service.category)
                    const serviceDoc = await trans.get(serviceDocRef)
                    let items = serviceDoc.data().services

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id == service.id) {
                            items[i] = service
                        }
                    }
                    trans.update(serviceDocRef, {
                        services: items
                    })
                })
            }
        },
        async removeService(context, service) {
            const serviceDocRef = doc(db, 'services', service.category)
            await updateDoc(serviceDocRef, {
                services: arrayRemove(service)
            })
        }
    },
    getters: {
        services(state) {
            return state.services;
        },
    },
}