/*
 * value is stored in DB
 */

const categories = [
    { name: "Autres services", value: "other", image: "animal.jpg" },
    { name: "Animaux", value: "animal", image: "animal.jpg" },
    { name: "Bricolage, travaux, tâches ménagères", value: "home", image: "home.jpg" },
    { name: "Cuisine", value: "kitchen", image: "kitchen.jpg" },
    { name: "Informatique", value: "computer", image: "computer.jpg" },
    { name: "Jardinage", value: "garden", image: "garden.jpg" },
    { name: "Loisirs, jeux, sport", value: "hobby", image: "hobby.jpg" },
    { name: "Mobilité", value: "transport", image: "transport.jpg" },
    { name: "Savoirs, connaissances", value: "teaching", image: "teaching.jpg" },
    { name: "Décoration, couture, travaux manuels", value: "diy", image: "diy.jpg" },
    { name: "Social", value: "social", image: "social.jpg" },
    { name: "Prêt de biens", value: "loan", image: "loan.jpg" },
    { name: "Don de biens", value: "gift", image: "gift.jpg" }
]

function getCategoryNameByValue(value) {
    let item = categories.find(c => c.value == value)
    if (item) {
        return item.name
    } else {
        return undefined
    }
}

function getCategoryByValue(value) {
    return categories.find(c => c.value == value)
}

function getCategoryImageByValue(value) {
    let item = categories.find(c => c.value == value)
    if (item) {
        return item.image
    } else {
        return undefined
    }
}

/*
 * Form
 */
const publicationTypes = [
    {name: 'Demande', value: true}, 
    {name: 'Offre', value: false}
]

/*
 * value is stored in DB
 */

const towns = [
    { name: "Raismes", value: "59590" },
    { name: "Escautpont", value: "59278" },
    { name: "Fresnes-sur-Escaut", value: "59970" },
    { name: "Vieux-Condé", value: "59690" },
    { name: "Condé-sur-l'Escaut", value: "59163" },
    { name: "Bruay-sur-l'Escaut", value: "59860" },
    { name: "Beuvrages", value: "59192" },
    { name: "Anzin", value: "59410" },
    { name: "Saint-Saulve", value: "59880" },
    { name: "Onnaing", value: "59264" },
    { name: "Quarouble", value: "59243" },
    { name: "Valenciennes", value: "59300" },
    { name: "Petite-Forêt", value: "59494" },
    { name: "Marly", value: "59770" },
    { name: "Quiévrechain", value: "59920" }
]

function getTownName(value) {
    const town = towns.find(t => t.value == value)
    if (town) {
        return town.name
    }
    return null
}

/**
 * Form validators
 */
const notEmptyRule = v => v && !!v.trim() || 'Le champ ne peut être vide'
const townRules = [(v) => !!v || "Veuillez choisir la ville"];
const nameRules = [
    (v) => !!v || "Veuillez saisir un nom/prénom",
    notEmptyRule
];

const emailRules = [
    (v) => !!v || "Veuillez saisir un mail",
    (v) => /.+@.+\..+/.test(v) || "Le mail n'est pas correct",
];

const emailOrNoneRules = [
    (v) => (v.length == 0 || /.+@.+\..+/.test(v)) || "Le mail n'est pas correct",
];

const numberRules = [
    (v) => (v.length == 10 || v.length == 0) || "Le numéro n'est pas correct",
    (v) => !isNaN(v) || "Seuls les chiffres sont autorisés",
];

const onlyNumberOrNonRules = [
    (v) => !isNaN(v) || "Seuls les chiffres sont autorisés",
];

const descriptionRules = [(v) => v.length < 200 || "La description est très longue"];

const messageRules = [(v) => v.length < 100 || "Le message est très long"];
const subjectRules = [(v) => !!v || "Veuillez choisir un sujet"]
const requiredRules = [(v) => !!v || "Champ obligatoire"]
const passwordRules = [
    (v) => v.length > 5 || "Au moins 6 caractères",
    notEmptyRule
]

function formatISOonlydate(iso) {
    return iso.split("-").reverse().join('/')
}

function formatISOdateToHours(time) {

    const hour = time.split(":")[0]
    const min = time.split(":")[1]

    return hour + "h" + min
}

function gmtToLocale(gmt) {
    //console.log("gmt", gmt)
    var myDate = new Date(gmt)
    //console.log("mydate", myDate)
    return myDate.toLocaleString().split(',').join('-')
}

function gmtToLocaleDate(gmt) {
    //console.log("gmt", gmt)
    var myDate = new Date(gmt)
    //console.log("mydate", myDate)
    return myDate.toLocaleString().split(',')[0]
}

function firebaseTimestampToDate(timestamp) {
    //let iso = new Date(timestamp.seconds)
    let res = timestamp.toDate().toISOString()

    let date = res.split("T")[0]
    date = formatISOonlydate(date)
    //console.log("date", date)

    //let time = res.split("T")[1]
    //time = formatISOdateToHours(time)
    //console.log("time", time)

    return date// + ', ' + time
}

function goToPath(path) {
    let route = this.$route.fullPath
    if (route != path) {
        this.$router.push(path)
    }
}

function getCurrentPath() {
    return this.$route.fullPath
}

/**
 * Sort options
 */
const sortOptions = [
    { title: "Le plus récent", value: "desc" },
    { title: "Le plus ancien", value: "asc" }
];

const sortPublicationTypes = [
    {title: 'Tout', value: null},
    {title: 'Demande', value: true}, 
    {title: 'Offre', value: false}
]

// Unique ID generator
function generateId() {
    return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
}

export default {
    data() {
        return {
            townRules,
            requiredRules,
            passwordRules,
            nameRules,
            emailRules,
            emailOrNoneRules,
            numberRules,
            onlyNumberOrNonRules,
            descriptionRules,
            messageRules,
            subjectRules,
            // towns
            towns,
            // categories
            categories,
            // sort
            sortOptions,
            sortPublicationTypes,
            // publication Demand/Offre
            publicationTypes
        }
    },
    methods: {
        goToPath,
        getCurrentPath,
        // town
        getTownName,
        // categories
        getCategoryByValue,
        getCategoryNameByValue,
        getCategoryImageByValue,
        // date time format
        formatISOonlydate,
        formatISOdateToHours,
        firebaseTimestampToDate,
        gmtToLocale,
        gmtToLocaleDate,
        // id
        generateId
    }
}
// export default {
//     generateId,
//     //  router
//     goToPath,
//     getCurrentPath,
//     // date time
//     formatISOonlydate,
//     formatISOdateToHours,
//     firebaseTimestampToDate,
//     gmtToLocale,
//     gmtToLocaleDate,
//     // rules
//     townRules,
//     requiredRules,
//     passwordRules,
//     nameRules,
//     emailRules,
//     numberRules,
//     onlyNumbersRules,
//     messageRules,
//     subjectRules,
//     // lists
//     sortOptions,
//     // towns
//     towns,
//     getTownName,
//     // categories
//     categories,
//     getCategoryByValue,
//     getCategoryNameByValue,
//     getCategoryImageByValue
// }
