<template>
  <v-footer dark>
    <v-card elevation="0" color="transparent" class="full-width">
      <v-card-text>
        <v-row align="center">
          <v-col
            class="d-flex justify-center redd"
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            align="center"
          >
            <v-row class="justify-center">
              <v-col xl="4" lg="4" md="4" sm="4" cols="12">
                <span
                  >{{ new Date().getFullYear() }} — Troc D'Heures Raismois v{{ version }}</span
                >
              </v-col>
              <v-col xl="4" lg="4" md="4" sm="4" cols="12">
                <a style="color: #aca7a7" href="/about#mentions">
                  <span>Mentions légales</span>
                </a>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="text-center" xl="6" lg="6" md="6" sm="6" cols="12">
            <v-row justify="center">
              <v-col
                v-for="(item, i) in items"
                :key="i"
                cols="2"
                class="text-center"
              >
                <v-btn icon :href="item.href" :target="item.target">
                  <v-icon dark md>{{ item.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import {version} from "../../package.json"

export default {
  name: "Footer",

  data: () => ({
    version: version,
    items: [
      {
        icon: "mdi-facebook",
        href: "https://www.facebook.com/Troc-dheures-raismois-108325714810910/",
        target: "_blank",
      },
      { icon: "mdi-email", href: "/contact", target: "" },
      { icon: "mdi-phone", href: "/contact", target: "" },
      { icon: "mdi-information", href: "/about", target: "" },
    ],
  }),
};
</script>