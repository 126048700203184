<template>
  <v-app-bar
    app
    prominent
    clipped-left
    class="white text-center"
    style="padding-left: 0px; padding-right: 0px"
    height="84px"
  >
    <v-row
      class="hidden-sm-and-up ma-0 full-width full-height"
      justify="space-between"
      align="center"
    >
      <v-col class="pa-0">
        <span
          class="title-font gradient-text pa-0"
          style="font-size: 22px"
          @click="toHomePage"
          >Troc D'Heures Raismois</span
        >
      </v-col>
      <v-col cols="2" class="pa-0">
        <v-menu  offset-y min-width="100%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="hidden-sm-and-up" icon v-bind="attrs" v-on="on">
              <v-icon x-large>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list dense >
            <v-list-item link class="pa-0">
              <v-btn text to="/" class="pa-8 full-width">
                <v-icon left dark>mdi-home</v-icon>
                <span >Accueil</span>
              </v-btn>
            </v-list-item>
            <v-list-item link class="pa-0">
              <v-btn text to="/contact" class="pa-8 full-width">
                Contact
              </v-btn>
            </v-list-item>
            <v-list-item link class="pa-0">
              <v-btn text to="/about" class="pa-8 full-width"> à propos </v-btn>
            </v-list-item>
            <v-list-item link class="pa-0">
              <v-btn
                v-if="true"
                text
                @click="emitAuthEvent"
                class="green pa-8 full-width"
              >
                <v-icon left dark>mdi-lock</v-icon>
                <div>Espace Personnel</div>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-img
      contain
      src="./../assets/sel-logo-mini.jpg"
      width="150px"
      height="100%"
      class="hidden-xs-only"
      @click="toHomePage"
    ></v-img>

    <v-spacer class="hidden-md-and-down"></v-spacer>

    <v-toolbar-title @click="toHomePage">
      <div class="text-lg-h3 hidden-md-and-down gradient-text">
        <span class="title-font">Troc D'Heures Raismois</span>
      </div>
    </v-toolbar-title>

    <v-spacer class="hidden-md-and-down"></v-spacer>

    <v-toolbar-items class="hidden-xs-only">
      <v-btn text to="/">
        <v-icon left dark>mdi-home</v-icon>
        <span >Accueil</span>
      </v-btn>
      <v-btn text to="/contact"> Contact </v-btn>
      <v-btn text to="/about"> à propos </v-btn>
      <v-btn v-if="true" text @click="emitAuthEvent" class="success">
        <v-icon left dark>mdi-lock</v-icon>
        <div>Espace Personnel</div>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      out: "Log Out",
      drawer: false,
      items: [
        { to: "/", icon: "mdi-home", title: "Accueil", color: "" },
        {
          to: "/contact",
          icon: "mdi-card-account-phone",
          title: "Contact",
          color: "",
        },
        { to: "/about", icon: "mdi-information", title: "A Propos", color: "" },
        {
          to: "/espace",
          icon: "mdi-lock",
          title: "Espace Personnel",
          color: "success",
        },
      ],
    };
  },
  methods: {
    user() {},
    // trigers event to parent component for auth dialog
    emitAuthEvent() {
      console.log("emitting with route ", this.$route.name);
      this.$emit("auth-event");
    },
    toHomePage() {
      this.goToPath("/");
    },
  },
  computed: {
    isVisitor() {
      return false;
    },
    signInOutButtonText() {
      return this.$store.getters.actualUser.id == null
        ? "Espace Personnel"
        : "Déconnexion";
    },
    NavBtnText() {
      let size = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          size = "h6";
          break;
        case "sm":
          size = "h5";
          break;
        case "md":
          size = "h4";
          break;
        case "lg":
          size = "h3";
          break;
        case "xl":
          size = "h2";
          break;
      }

      return size;
    },
  },
  watch: {},
};
</script>

<style scoped>

/deep/ .v-toolbar__content {
  padding-top: 0;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
}

.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #94c11e, #f29209);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>