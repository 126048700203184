import {
    db,
    updateDoc,
    arrayRemove,
    arrayUnion,
    getDoc,
    doc,
    runTransaction
} from "./../../firebaseConfig"

// Unique ID generator
function generateId() {
    return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
}

export default {
    state: {
        eventDocRef: doc(db, 'events', 'eventDoc'),
        events: []
    },
    mutations: {
        setEvents(state, events) {
            state.events = events
        }
    },
    actions: {
        async findEvents({ state, commit }) {
            const eventDoc = await getDoc(state.eventDocRef)
            let events = eventDoc.data().events
            commit('setEvents', events)
        },
        async saveEvent({ state }, event) {
            console.log("STORE into saveEvent", event)
            if (!event.id) {
                console.log("generating")
                event.id = generateId()
                console.log("event save", event)
                await updateDoc(state.eventDocRef, {
                    events: arrayUnion(event)
                });
            } else {
                /*
                 * Read the whole event doc so we have a up-to-date list
                 * Only after then update event
                 */
                await runTransaction(db, async (trans) => {
                    let eventDoc = await trans.get(state.eventDocRef)
                    let items = eventDoc.data().events

                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id == event.id) {
                            items[i] = event
                        }
                    }
                    trans.update(state.eventDocRef, {
                        events: items
                    })
                })
            }
        },
        async removeEvent({ state }, event) {
            await updateDoc(state.eventDocRef, {
                events: arrayRemove(event)
            });
        }
    },
    getters: {
        events(state) {
            return state.events
        }
    }
}