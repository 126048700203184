import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store"
import {
  auth
} from './../firebaseConfig'

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: () => import('@/views/Home.vue'),
  },
  {
    path: "/about",
    name: "About",
    component: () => import('@/views/About.vue')
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import('@/views/Contact.vue'),
  },
  {
    path: "/espace",
    name: "Feed",
    component: () => import('@/views/Feed.vue'),
    meta: {
      authRequired: true,
      adminRequired: false
    }
  },
  {
    path: "/utilisateurs",
    name: "UserManager",
    component: () => import('@/views/UserManager.vue'),
    meta: {
      authRequired: true,
      adminRequired: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});


// function isUserFetched(to, from, next) {
//   let user = store.getters.user
//   console.log("ROUTER user", user)
//   if (user == null) {
//     console.log("user is null")
//     next('/')
//   }
//   next()
// }

router.beforeEach(async (to, from, next) => {
  console.log("TO FROM", to, from)
  // auth required
  let user = store.getters.user
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (user == null) {
      let authUser = auth.currentUser
      console.log("ROUTER: authUser", authUser)
      if (authUser == null) {
        next('/')
      } else {
        await store.dispatch('setup', authUser)
      }
    }
  }
  // admin required
  user = store.getters.user
  if (to.matched.some((record) => record.meta.adminRequired)) {
    if (user.admin) {
      next()
    } else {
      console.log("ROUTER: not admin, going back")
      next("/")
    }
  }
  // admin required
  next()
});

export default router;