import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from './firebaseConfig'
import mixin from "./mixins"

Vue.config.productionTip = true

Vue.mixin(mixin)

let app
auth.onAuthStateChanged(async (authUser) => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

  if (authUser) {
    console.log("Main.js, found authUser", authUser)
    await store.dispatch('setup', authUser)
  } else {
    console.log("Main.js, NO User found")
  }
})


